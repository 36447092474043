'use strict';

angular.module('mvpcarrefourApp')
  .controller('ManageProductsCtrl', ['$scope', 'Product', 'Modal',
    function($scope, Product, Modal) {
      Product.query(function(products) {
        $scope.products = products;
        // pagination controls
        $scope.currentPage = 1;
        $scope.totalItems = $scope.products.length;
        $scope.itemsPerPage = 10; // items per page
        $scope.noOfPages = Math.ceil($scope.totalItems / $scope.itemsPerPage);
      });

      $scope.deleteProduct = Modal.confirm.delete(function(p) {
        if (p.slug == 'all') return;
        p.$remove(p._id, function(resp) {
          console.log(resp)
          $scope.products.splice($scope.products.indexOf(p), 1);
        })
      });
    }
  ])
  .controller('ManageProductsEditCtrl', ['$scope', '$stateParams', '$state', 'Upload', 'categories', 'product', 'Product', 'Modal',
    function($scope, $stateParams, $state, Upload, categories, product, Product, Modal) {

      $scope.categories = categories;
      $scope.product = product;

      function uploadFiles(file, main) {
        var options = {
          width: main ? 400 : 630,
          height: main ? 400 : 380,
          type: 'image/png',
          centerCrop: true,
          restoreExif: false
        };

        Upload.resize(file, options)
          .then(function(resizedFile) {
            Upload.upload({
              url: '/api/uploads',
              data: { file: resizedFile }
            })
              .then(function(resp) {
                if (main) {
                  $scope.product.urls.media.mainImage = resp.data.url;
                }
                else {
                  $scope.product.urls.media.alternativeImages.push(resp.data.url);
                }
              }, function(resp) {
                $scope.errorMsg = resp.status + ': ' + resp.data;
              }, function(evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
              });
          })
      };


      $scope.addFiles = function(files, invalidFiles, mainImage) {
        var images = [];
        if (Array.isArray(files))
          images = files;
        else
          images.push(files)

        if (images.length > 0) {
          images.forEach(function(file) {
            uploadFiles(file, mainImage)
          })
        }
      }

      $scope.save = function(form) {
        if (form.$valid) {
          Product.update($scope.product._id, $scope.product,  Modal.confirm.info({
            title: "Producto modificado",
            msg: "El producto ha sido modificado correctamente."
          },function(info) {
            $state.go('manage-products')
          }), Modal.confirm.info({
            title: "Error",
            msg: "Ha ocurrido un error al intentar modificar el producto."
          },function(err) {
            console.log(err);
          }))
        }
      }

      $scope.removeImg = function(image) {
        _.pull($scope.product.urls.media.alternativeImages, image);
      }
    }
  ])
  .controller('ManageProductsAddCtrl', ['$scope', '$state', 'Product', 'categories', 'Upload', 'Modal',
    function($scope, $state, Product, categories, Upload, Modal) {

      $scope.regex = "/^((https?|ftp):\\/\\/)?([a-z]+[.])?[a-z0-9-]+([.][a-z]{1,4}){1,2}(\\/.*[?].*)?$/i";

      //Inicializamos un modelo de producto para rellenarlo mediante el formulario
      $scope.product = {
        active: false,
        urls: {
          media: {
            mainImage: "",
            alternativeImages: []
          }
        }
      }

      //Agrupamos las subcategorias para mostrarlas listadas
      let _subcategories = [];
      _.forEach(categories, c => {
        _.forEach(c.children, sc => {
          sc.parent = c.name;
          _subcategories.push(sc);
        })
      })

      $scope.subcategories = _subcategories;

      function uploadFiles(file, main) {
        //Dependiendo del tipo de imagen se especifican unas dimensiones diferentes
        var options = {
          width: main ? 400 : 630,
          height: main ? 400 : 380,
          type: 'image/png',
          centerCrop: true,
          restoreExif: false
        };

        Upload.resize(file, options)
          .then(function(resizedFile) {
            Upload.upload({
              url: '/api/uploads',
              data: { file: resizedFile }
            })
              .then(function(resp) {
                if (main) {
                  $scope.product.urls.media.mainImage = resp.data.url;
                }
                else {
                  $scope.product.urls.media.alternativeImages.push(resp.data.url);
                }
              }, function(resp) {
                $scope.errorMsg = resp.status + ': ' + resp.data;
              }, function(evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
              });
          })
      };

      $scope.addFiles = function(files, invalidFiles, mainImage) {
        var images = [];
        if (Array.isArray(files))
          images = files;
        else
          images.push(files)

        if (images.length > 0) {
          images.forEach(function(file) {
            uploadFiles(file, mainImage)
          })
        }
      }

      $scope.save = function(form) {
        if (form.$valid) {
          Product.save($scope.product, Modal.confirm.info({
            title: "Producto creado",
            msg: "El producto ha sido creado correctamente."
          },function() {
            $state.go('manage-products')
          }),
            Modal.confirm.info({
              title: "Error",
              msg: "Ha ocurrido un error al crear el producto"
            },function(err) {
              console.log(err)
            })
          )
        }
      }

      $scope.removeImg = function(image) {
        _.pull($scope.product.urls.media.alternativeImages, image);
      }
    }
  ]);
